import gql from 'graphql-tag';
import {
  AddBundledDataSourceInstanceMutationVars,
  AddCustomDataSourceInstanceMutationVars
} from './AddDataSourceInstanceMutation';

export type UpdateBundledDataSourceInstanceMutationVars =
  AddBundledDataSourceInstanceMutationVars & {
    id: string;
  };

export const UPDATE_BUNDLED_DATA_SOURCE_INSTANCE_MUTATION_NAME = 'UpdateBundledDataSourceInstance';
export const UPDATE_BUNDLED_DATA_SOURCE_INSTANCE_MUTATION = gql`
  mutation ${UPDATE_BUNDLED_DATA_SOURCE_INSTANCE_MUTATION_NAME}($id: ID!, $input: BundledDataSourceInstanceInput!) {
    dataSourceInstance: updateBundledDataSourceInstance(id: $id, input: $input) {
      id
      name
      dataSource {
        key
      }
    }
  }
`;

export type UpdateCustomDataSourceInstanceMutationVars = AddCustomDataSourceInstanceMutationVars & {
  id: string;
};

export const UPDATE_CUSTOM_DATA_SOURCE_INSTANCE_MUTATION = gql`
  mutation UpdatCustomDataSourceInstance($id: ID!, $input: CustomDataSourceInstanceInput!) {
    dataSourceInstance: updateCustomDataSourceInstance(id: $id, input: $input) {
      id
      name
      dataSource {
        key
      }
    }
  }
`;
