export const constants = {
  FieldTypeInterfaceTypename: 'IGQLFieldType',

  TenantCustomFieldInterfaceTypename: 'ISubjectCustomField',
  TenantComputedFieldTypename: 'SubjectComputedField',
  TenantInputFieldTypename: 'SubjectInputField',

  VisualisationDataPointTypename: 'VisualisationDataPointsConnection',
  FormattedDataPointTypename: 'FormattedDataPoint',
  FormattedDataPointItemTypename: 'FormattedDataPointItem',

  VisulaisationDisplayOptionInterfaceType: 'IDisplayOption',
  DataTypeValueInterfaceTypename: 'IDataTypeValue',

  DataSourceInterfaceTypename: 'IDataSource'
} as const;
