import { languages, editor, Position, CancellationToken, IRange } from 'monaco-editor';
import { language as liquidLanguage } from 'monaco-editor/esm/vs/basic-languages/liquid/liquid.js';

const fixedLanguage = {
  ...liquidLanguage,
  builtinTags: [...liquidLanguage.builtinTags.filter(tag => tag !== 'elseif'), 'elsif']
};

interface LiquidArguments {
  [key: string]: string | LiquidArguments;
}

export type LiquidOptions = {
  possibleArguments?: LiquidArguments;
  extraFilters?: Array<string>;
  excludeFilters?: Array<string>;
  extraTags?: Array<string>;
  excludeTags?: Array<string>;
};

export const getPossibleLiquidArgumentsFromArray = (array: string[]): LiquidArguments => {
  const result: LiquidArguments = {};

  for (const item of array) {
    result[item] = '';
  }

  return result;
};

const DefaultLiquidOptions: LiquidOptions = {
  extraFilters: ['plus_time', 'round_to_next', 'age', 'cui_date_time', 'cui_date', 'cui_time'],
  excludeTags: [
    'tablerow',
    'endtablerow',
    'comment',
    'endcomment',
    'liquid',
    'raw',
    'endraw',
    'render',
    'layout',
    'include'
  ]
};

export class LiquidCodeCompletionProvider implements languages.CompletionItemProvider {
  private readonly argumentsSuggestionRegex: RegExp;
  private readonly nestedArgumentsSuggestionRegex: RegExp;
  private readonly ifArgumentsSuggestionRegex: RegExp;
  private readonly arguments?: LiquidArguments;
  private readonly tags: Array<string>;
  private readonly filters: Array<string>;
  private readonly ifArguments;

  public readonly triggerCharacters: Array<string>;

  public constructor(options?: LiquidOptions) {
    const normalisedOptions = {
      ...DefaultLiquidOptions,
      ...(options ?? {})
    };

    this.arguments = normalisedOptions.possibleArguments;
    this.tags = this.getTags(normalisedOptions);
    this.filters = this.getFilters(normalisedOptions);

    this.triggerCharacters = [
      ...this.tags,
      '{',
      '%',
      ' ',
      '|',
      '=',
      '==',
      '<',
      '>',
      '>=',
      '<=',
      '!=',
      '*',
      '/',
      ':',
      '.'
    ];

    const validArgumentPrefix = [
      ...this.tags,
      '{{',
      '=',
      '==',
      '!=',
      '<',
      '<=',
      '>',
      '>=',
      '*',
      '/',
      ':',
      'contains'
    ]
      .map(this.escapeRegExp)
      .join('|');

    this.argumentsSuggestionRegex = new RegExp(`(${validArgumentPrefix})\\s*$`);
    this.nestedArgumentsSuggestionRegex = new RegExp(
      `(${validArgumentPrefix})\\s*(?:[\\w\\d_-]+\\.)+[\\w\\d_-]*$`
    );

    this.ifArgumentsSuggestionRegex = /^\{%\s*if\s*[a-z0-9"]+$/;
    this.ifArguments = ['==', '!=', '<', '<=', '>', '>=', 'contains'];
  }

  public provideCompletionItems(
    model: editor.ITextModel,
    position: Position,
    _: languages.CompletionContext,
    __: CancellationToken
  ): languages.ProviderResult<languages.CompletionList> {
    const lineContent = model.getLineContent(position.lineNumber);
    const textToPosition = lineContent.substring(0, position.column - 1);

    if (!this.isInLiquidTag(textToPosition)) {
      return { suggestions: [] };
    }

    // calculate range to pass to suggestions
    const wordUntilPosition = model.getWordUntilPosition(position);
    const range = {
      startLineNumber: position.lineNumber,
      startColumn: wordUntilPosition.startColumn,
      endLineNumber: position.lineNumber,
      endColumn: wordUntilPosition.endColumn
    };

    if (textToPosition.match(/{%\s*$/)) {
      return {
        suggestions: this.tags.map(tag => ({
          label: tag,
          kind: languages.CompletionItemKind.Keyword,
          insertText: tag,
          range
        }))
      };
    }

    if (textToPosition.match(/\|\s*$/)) {
      return {
        suggestions: this.filters.map(filter => ({
          label: filter,
          kind: languages.CompletionItemKind.Keyword,
          insertText: filter,
          range
        }))
      };
    }

    if (this.arguments && textToPosition.match(this.nestedArgumentsSuggestionRegex)) {
      const substringFrom = Math.max(
        ...this.triggerCharacters
          .filter(x => x !== '.')
          .map(x => textToPosition.lastIndexOf(x) + 1),
        0
      );
      const nestedSplits = textToPosition
        .substring(substringFrom)
        .split('.')
        .filter(x => x !== '');

      let currentArgs = this.arguments;
      for (let i = 0; i < nestedSplits.length; i++) {
        const currentProperty = this.extractTargetObjectProperty(nestedSplits[i]);
        if (!(currentProperty in currentArgs) || typeof currentArgs[currentProperty] !== 'object') {
          if (i === nestedSplits.length - 1) {
            return { suggestions: this.getObjectSuggestions(currentArgs, range) };
          }
          break;
        }

        currentArgs = currentArgs[currentProperty];
        if (i === nestedSplits.length - 1) {
          return { suggestions: this.getObjectSuggestions(currentArgs, range) };
        }
      }

      return { suggestions: [] };
    }

    if (this.arguments && textToPosition.match(this.argumentsSuggestionRegex)) {
      return { suggestions: this.getObjectSuggestions(this.arguments, range) };
    }

    if (textToPosition.match(this.ifArgumentsSuggestionRegex)) {
      return {
        suggestions: this.ifArguments.map(possibleArgument => ({
          label: possibleArgument,
          kind: languages.CompletionItemKind.Operator,
          insertText: possibleArgument,
          range
        }))
      };
    }

    return { suggestions: [] };
  }

  private getFilters = (options: LiquidOptions): Array<string> => {
    return [...fixedLanguage.builtinFilters, ...(options.extraFilters ?? [])].filter(
      filter => !options.excludeFilters || !options.excludeFilters.includes(filter)
    );
  };

  private getTags = (options: LiquidOptions): Array<string> => {
    return [...fixedLanguage.builtinTags, ...(options.extraTags ?? [])].filter(
      filter => !options.excludeTags || !options.excludeTags.includes(filter)
    );
  };

  private escapeRegExp = (value: string) => {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  private isInLiquidTag(textToPosition: string): boolean {
    return (
      textToPosition.lastIndexOf('{{') > textToPosition.lastIndexOf('}}') ||
      textToPosition.lastIndexOf('{%') > textToPosition.lastIndexOf('%}')
    );
  }

  private extractTargetObjectProperty(property: string) {
    const regex = /[=><!|%{}*/:.\s]+(.+)/;
    const match = property.match(regex);
    if (match) {
      const parts = match[1].split(/[=><!|%{}*/:.\s]+/).map(part => part.trim());
      return parts[parts.length - 1];
    }
    return property;
  }

  private getObjectSuggestions(args: LiquidArguments, range: IRange) {
    return Object.entries(args).map(([key, values]) => {
      const kind =
        typeof values === 'object'
          ? languages.CompletionItemKind.Class
          : languages.CompletionItemKind.Field;

      return {
        label: key,
        kind: kind,
        insertText: key,
        range
      };
    });
  }
}
