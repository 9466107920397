import { assertUnreachable } from './AssertUnreachable';

export enum DataType {
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATE_TIME',
  DECIMAL = 'DECIMAL',
  INTEGER = 'INTEGER',
  STRING = 'STRING',
  TIME = 'TIME',
  USERIDENTIFIER = 'USER_IDENTIFIER'
}

export type TypedField = {
  key: string;
  dataType: DataType;
};

export enum UserIdentifierType {
  User = 'USER',
  Subject = 'SUBJECT'
}

export type UserIdentifier = {
  identifier: string;
  userIdentifierType: UserIdentifierType;
};

export const DataTypeValueTypeNames = {
  [DataType.STRING]: 'StringDataTypeValue',
  [DataType.INTEGER]: 'IntegerDataTypeValue',
  [DataType.DECIMAL]: 'DecimalDataTypeValue',
  [DataType.BOOLEAN]: 'BooleanDataTypeValue',
  [DataType.DATETIME]: 'DateTimeDataTypeValue',
  [DataType.DATE]: 'DateDataTypeValue',
  [DataType.TIME]: 'TimeDataTypeValue',
  [DataType.USERIDENTIFIER]: 'UserIdentifierDataTypeValue'
} as const;

export type DataTypeValueMap = {
  [DataType.STRING]: { dataType: DataType.STRING; stringValue: string | null };
  [DataType.INTEGER]: { dataType: DataType.INTEGER; integerValue: number | null };
  [DataType.DECIMAL]: { dataType: DataType.DECIMAL; decimalValue: number | null };
  [DataType.BOOLEAN]: { dataType: DataType.BOOLEAN; booleanValue: boolean | null };
  [DataType.DATETIME]: { dataType: DataType.DATETIME; dateTimeValue: string | null };
  [DataType.DATE]: { dataType: DataType.DATE; dateValue: string | null };
  [DataType.TIME]: { dataType: DataType.TIME; timeValue: string | null };
  [DataType.USERIDENTIFIER]: {
    dataType: DataType.USERIDENTIFIER;
    userIdentifierValue: UserIdentifier | null;
  };
};

export type DataTypeValue<DT extends DataType = DataType> = DataTypeValueMap[DT];

export function getDataTypeValue(
  value: DataTypeValue<DataType.STRING>
): DataTypeValue<DataType.STRING>['stringValue'];

export function getDataTypeValue(
  value: DataTypeValue<DataType.INTEGER>
): DataTypeValue<DataType.INTEGER>['integerValue'];

export function getDataTypeValue(
  value: DataTypeValue<DataType.DECIMAL>
): DataTypeValue<DataType.DECIMAL>['decimalValue'];

export function getDataTypeValue(
  value: DataTypeValue<DataType.BOOLEAN>
): DataTypeValue<DataType.BOOLEAN>['booleanValue'];

export function getDataTypeValue(
  value: DataTypeValue<DataType.DATETIME>
): DataTypeValue<DataType.DATETIME>['dateTimeValue'];

export function getDataTypeValue(
  value: DataTypeValue<DataType.DATE>
): DataTypeValue<DataType.DATE>['dateValue'];

export function getDataTypeValue(
  value: DataTypeValue<DataType.TIME>
): DataTypeValue<DataType.TIME>['timeValue'];

export function getDataTypeValue(
  value: DataTypeValue<DataType.USERIDENTIFIER>
): DataTypeValue<DataType.USERIDENTIFIER>['userIdentifierValue'];

export function getDataTypeValue(
  value: Exclude<DataTypeValue, DataTypeValue<DataType.USERIDENTIFIER>>
):
  | DataTypeValue<DataType.STRING>['stringValue']
  | DataTypeValue<DataType.INTEGER>['integerValue']
  | DataTypeValue<DataType.DECIMAL>['decimalValue']
  | DataTypeValue<DataType.BOOLEAN>['booleanValue']
  | DataTypeValue<DataType.DATETIME>['dateTimeValue']
  | DataTypeValue<DataType.DATE>['dateValue']
  | DataTypeValue<DataType.TIME>['timeValue'];

export function getDataTypeValue(
  value: DataTypeValue
):
  | DataTypeValue<DataType.STRING>['stringValue']
  | DataTypeValue<DataType.INTEGER>['integerValue']
  | DataTypeValue<DataType.DECIMAL>['decimalValue']
  | DataTypeValue<DataType.BOOLEAN>['booleanValue']
  | DataTypeValue<DataType.DATETIME>['dateTimeValue']
  | DataTypeValue<DataType.DATE>['dateValue']
  | DataTypeValue<DataType.TIME>['timeValue']
  | DataTypeValue<DataType.USERIDENTIFIER>['userIdentifierValue'];

export function getDataTypeValue(value: DataTypeValue) {
  switch (value.dataType) {
    case DataType.STRING:
      return value.stringValue;
    case DataType.INTEGER:
      return value.integerValue;
    case DataType.DECIMAL:
      return value.decimalValue;
    case DataType.BOOLEAN:
      return value.booleanValue;
    case DataType.DATETIME:
      return value.dateTimeValue;
    case DataType.DATE:
      return value.dateValue;
    case DataType.TIME:
      return value.timeValue;
    case DataType.USERIDENTIFIER:
      return value.userIdentifierValue;
    default:
      assertUnreachable(value);
  }
}
