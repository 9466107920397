import {
  FieldFunctionOptions,
  FieldPolicy,
  InMemoryCache,
  InMemoryCacheConfig,
  Reference
} from '@apollo/client';
import {
  BooleanDataTypeValue,
  BundledDataSource,
  CustomDataSource,
  DataSourceInstance,
  DateDataTypeValue,
  DateTimeDataTypeValue,
  DecimalDataTypeValue,
  FormattedDataPoint,
  FormattedDataPointItem,
  GetRenderedHtmlResult,
  GraphChartDisplayOption,
  Group,
  InputField,
  IntegerDataTypeValue,
  LiquidDisplayOption,
  MultipleChoiceFieldType,
  OutputTypeOf,
  PerformActionResult,
  PieChartDisplayOption,
  RepositorySettings,
  SectionConfiguration,
  SimpleFieldType,
  StringDataTypeValue,
  StructuralEntity as StructuralEntityGql,
  StructuredDataProviderType,
  StructureType,
  SubjectAccessInviteStatus,
  SubjectComputedField as SubjectComputedFieldGqlType,
  SubjectInputField,
  TableDisplayOption,
  Tenant,
  TenantCustomFieldGroup,
  TenantFeature,
  TenantSubjectProvider,
  TenantUser,
  TimeDataTypeValue,
  UserIdentifierDataTypeValue
} from '../api.gen';
import { constants } from './Constants';
import { OmitTypename } from './Paging';

type ExtractTypename<T> = T extends { __typename?: infer U } ? U : never;

function assertTypename<T>(
  __typename: ExtractTypename<OutputTypeOf<T>>
): ExtractTypename<OutputTypeOf<T>> {
  return __typename;
}

function assertFields<T>(
  fields: Array<keyof OmitTypename<OutputTypeOf<T>>>
): Array<keyof OmitTypename<OutputTypeOf<T>>> {
  return fields;
}

function nodesMerge<T>(keyField: keyof OmitTypename<OutputTypeOf<T>> & string): FieldPolicy {
  return {
    keyArgs: false,
    merge(
      existing: { nodes: Array<OutputTypeOf<T> | Reference> },
      incoming: { nodes: Array<OutputTypeOf<T> | Reference> },
      { isReference, readField }: FieldFunctionOptions
    ): { nodes: Array<OutputTypeOf<T> | Reference> } {
      if (!incoming) return existing;
      if (!existing) return incoming;

      const newNodes = new Map<string, OutputTypeOf<T> | Reference>();
      const handleNode = (node: OutputTypeOf<T> | Reference) => {
        if (isReference(node)) {
          const id = readField<string>(keyField, node);
          if (id && !(id in newNodes)) {
            newNodes.set(id, node);
          }

          return;
        }

        const id = node[keyField];
        if (typeof id !== 'string') {
          console.warn(`Key field ${keyField} is not a string in node ${node}`);
        } else if (!(id in newNodes)) {
          newNodes.set(id, node);
        }
      };

      (existing.nodes ?? []).forEach(handleNode);
      (incoming.nodes ?? []).forEach(handleNode);

      return { ...incoming, nodes: Array.from(newNodes.values()) };
    }
  };
}

export const apolloCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        appConfig: {
          merge: true
        }
      }
    },
    [assertTypename<DataSourceInstance>('DataSourceInstance')]: {
      fields: {
        structuredDataProvider: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          }
        }
      }
    },
    [assertTypename<StructuredDataProviderType>('StructuredDataProviderType')]: {
      keyFields: false
    },
    [assertTypename<TenantSubjectProvider>('TenantSubjectProvider')]: {
      keyFields: false
    },
    [constants.VisualisationDataPointTypename]: {
      keyFields: false
    },
    [assertTypename<FormattedDataPoint>('FormattedDataPoint')]: {
      keyFields: false
    },
    [assertTypename<FormattedDataPointItem>('FormattedDataPointItem')]: {
      keyFields: false
    },
    DataExtractsConnection: {
      fields: {
        edges(existingEdges: Array<Reference>, { readField }) {
          return existingEdges.filter(existingEdge => {
            return (
              readField<string | undefined>('id', readField<Reference>('node', existingEdge)) !==
              undefined
            );
          });
        }
      }
    },
    [constants.DataSourceInterfaceTypename]: {
      keyFields: ['key']
    },
    [assertTypename<TenantUser>('TenantUser')]: {
      fields: {
        accessibleStructuralEntities: nodesMerge<StructuralEntityGql>('key')
      }
    },
    [assertTypename<Tenant>('Tenant')]: {
      fields: {
        structuralEntities: nodesMerge<StructuralEntityGql>('key')
      }
    },
    [assertTypename<RepositorySettings>('RepositorySettings')]: {
      keyFields: false
    },
    [assertTypename<SectionConfiguration>('SectionConfiguration')]: {
      keyFields: assertFields<SectionConfiguration>(['section'])
    },
    [assertTypename<PerformActionResult>('PerformActionResult')]: {
      keyFields: false
    },
    [assertTypename<TenantFeature>('TenantFeature')]: {
      keyFields: assertFields<TenantFeature>(['featureKey'])
    },
    [assertTypename<StructuralEntityGql>('StructuralEntity')]: {
      keyFields: ['key'],
      fields: {
        multiSubjectView: {
          merge(_, incoming) {
            return incoming;
          }
        }
      }
    },
    [assertTypename<StructureType>('StructureType')]: {
      keyFields: assertFields<StructureType>(['key'])
    },
    [assertTypename<Group>('Group')]: {
      keyFields: assertFields<Group>(['key'])
    },
    [assertTypename<TenantCustomFieldGroup>('TenantCustomFieldGroup')]: {
      keyFields: assertFields<TenantCustomFieldGroup>(['key'])
    },
    [constants.DataTypeValueInterfaceTypename]: {
      keyFields: false
    },
    [assertTypename<InputField>('InputField')]: {
      keyFields: assertFields<InputField>(['key'])
    },
    [constants.FieldTypeInterfaceTypename]: {
      keyFields: false
    },
    [constants.TenantCustomFieldInterfaceTypename]: {
      keyFields: ['key']
    },
    [constants.VisulaisationDisplayOptionInterfaceType]: {
      keyFields: false
    },
    [assertTypename<GetRenderedHtmlResult>('GetRenderedHtmlResult')]: {
      keyFields: false
    },
    [assertTypename<SubjectAccessInviteStatus>('SubjectAccessInviteStatus')]: {
      keyFields: false
    }
  },
  possibleTypes: {
    [constants.FieldTypeInterfaceTypename]: [
      assertTypename<SimpleFieldType>('SimpleFieldType'),
      assertTypename<MultipleChoiceFieldType>('MultipleChoiceFieldType')
    ],
    [constants.TenantCustomFieldInterfaceTypename]: [
      assertTypename<SubjectComputedFieldGqlType>('SubjectComputedField'),
      assertTypename<SubjectInputField>('SubjectInputField')
    ],
    [constants.VisulaisationDisplayOptionInterfaceType]: [
      assertTypename<TableDisplayOption>('TableDisplayOption'),
      assertTypename<PieChartDisplayOption>('PieChartDisplayOption'),
      assertTypename<GraphChartDisplayOption>('GraphChartDisplayOption'),
      assertTypename<LiquidDisplayOption>('LiquidDisplayOption')
    ],
    [constants.DataTypeValueInterfaceTypename]: [
      assertTypename<StringDataTypeValue>('StringDataTypeValue'),
      assertTypename<IntegerDataTypeValue>('IntegerDataTypeValue'),
      assertTypename<DecimalDataTypeValue>('DecimalDataTypeValue'),
      assertTypename<BooleanDataTypeValue>('BooleanDataTypeValue'),
      assertTypename<DateTimeDataTypeValue>('DateTimeDataTypeValue'),
      assertTypename<DateDataTypeValue>('DateDataTypeValue'),
      assertTypename<TimeDataTypeValue>('TimeDataTypeValue'),
      assertTypename<UserIdentifierDataTypeValue>('UserIdentifierDataTypeValue')
    ],
    [constants.DataSourceInterfaceTypename]: [
      assertTypename<CustomDataSource>('CustomDataSource'),
      assertTypename<BundledDataSource>('BundledDataSource')
    ]
  }
};

const cache = new InMemoryCache(apolloCacheConfig);

import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

export default cache;
