import gql from 'graphql-tag';
import { DataSourceInstance } from '../DataSource';
import { ITenant, TENANT_FIELDS_FRAGMENT, TENANT_FIELDS_FRAGMENT_NAME } from '../Tenant';

export type TenantWithSubjectProvider = ITenant<{ canModify: boolean }> & {
  tenantSubjectProvider: {
    __typename: 'TenantSubjectProvider';
    dataSource: Pick<DataSourceInstance, '__typename' | 'id' | 'name' | 'key'>;
    configuration: {
      __typename: string;
      subjectMapping: {
        __typename: string;
        externalIdMapping: string;
        liquidLookupExpression: string;
        customFieldMappings: {
          __typename: string;
          mapping: string;
          customField: {
            __typename: string;
            id: string;
          };
        }[];
      } | null;
    };
    subjectProvider: {
      __typename: 'SubjectProviderType';
      requiresSubjectFieldMapping: boolean;
    };
  } | null;
};

export interface TenantConfigurationQueryData {
  tenant: TenantWithSubjectProvider;
}

export const TENANT_CONFIGURATION_QUERY_NAME = 'TenantSubjectConfiguration';
export const TENANT_CONFIGURATION_QUERY = gql`
  ${TENANT_FIELDS_FRAGMENT}

  query ${TENANT_CONFIGURATION_QUERY_NAME} {
    tenant {
      ...${TENANT_FIELDS_FRAGMENT_NAME}
      customFields {
        primaryField {
            field {
              canModify
            }
          }
        groups { 
          customFields {
            field  {
              canModify
            }
          }
        }
        hiddenFields {
           field {
             canModify
           }
        }
      }
      tenantSubjectProvider {
        dataSource {
          id
          name
        }
        subjectProvider {
          requiresSubjectFieldMapping
        }
        configuration {
          subjectMapping {
            externalIdMapping
            liquidLookupExpression
            customFieldMappings {
              mapping
              customField {
                id
              }
            }
          }
        }
      }
    }
  }
`;
