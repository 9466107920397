import { ExcludeUndefined } from '@aireframe/shared-types';
import { $, query } from '../../api.gen';
import { ResultOf } from '@graphql-typed-document-node/core';

export const GetDataSourcesQuery = query('DataSources', q => [
  q.tenant(t => [
    t.__typename,
    t.id,
    t.dataSources({ onlyAddable: $('onlyAddable') }, ds => [
      ds.__typename,
      ds.nodes(node => [
        node.$on('BundledDataSource', bds => [bds.__typename, bds.name]),
        node.$on('CustomDataSource', cds => [cds.__typename]),
        node.key,
        node.configurationOptions(co => [co.__typename, co.key, co.isRequired, co.isSensitive])
      ])
    ])
  ])
]);

export type AddableDataSource = ExcludeUndefined<
  ExcludeUndefined<ResultOf<typeof GetDataSourcesQuery>['tenant']['dataSources']>['nodes']
>[number];
