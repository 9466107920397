import {
  ChartType,
  DefaultPieChartDisplayOptions,
  getPieChartItemName,
  isPieChartDisplayOptions,
  PieChartStyle
} from '@aireframe/shared-types';
import { EChartsOption } from 'echarts/types/dist/echarts';
import { ChartProps, useEchartsDataset } from '../DataVisualisationHelpers';
import { useVisualisationContext } from '../VisualisationContext';
import { ReactECharts } from './Echart-React';
import { DataSetItemValue, useChartColours } from './useChartColours';

const PieChartComponent: React.FC<ChartProps> = ({ data, height = 300 }) => {
  const { definition } = useVisualisationContext();
  const dataset = useEchartsDataset(data);

  const displayOptions = isPieChartDisplayOptions(definition.displayOptions)
    ? definition.displayOptions
    : DefaultPieChartDisplayOptions;

  const { colourMapper } = useChartColours(displayOptions, dataset);
  const chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: displayOptions.showLegend
      ? {
          type: 'scroll',
          orient: 'vertical',
          left: 'left'
        }
      : undefined,
    dataset,
    series: definition.series
      .filter(series => series.chartType === ChartType.PIE)
      .map(series => ({
        name: series.renderedName,
        type: 'pie',
        radius: displayOptions.style === PieChartStyle.DOUGHNUT ? ['40%', '80%'] : ['0%', '80%'],
        colorBy: 'data',
        encode: {
          value: series.key,
          itemName: getPieChartItemName(definition.series, series)
        },
        labelLine: {
          show: displayOptions.showLabel
        },
        label: {
          show: displayOptions.showLabel
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        itemStyle: {
          color: params => {
            const data = params.data as { [seriesKey: string]: DataSetItemValue };
            if (!(series.key in data)) {
              throw new Error(`Data for series ${series.key} not found`);
            }

            return colourMapper(series, data[series.key], params.dataIndex);
          }
        }
      }))
  };

  return <ReactECharts width={'99%'} height={height} option={chartOptions} />;
};

export default PieChartComponent;
