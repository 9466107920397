import { Grid, Tooltip, Typography } from '@mui/material';
import { OverflowTooltip } from '../OverflowTooltip/OverflowTooltip';
import DownloadingRoundedIcon from '@mui/icons-material/DownloadingRounded';

type WidgetHeaderProps = {
  staleIndicator?: React.ReactNode;
  children?: React.ReactNode;
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  title: string;
  updatePending?: boolean;
};

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  title,
  children,
  staleIndicator,
  justifyContent = 'center',
  updatePending = false
}) => (
  <Grid
    container
    item
    xs={12}
    sx={{ position: 'relative', padding: theme => theme.spacing(1.5) }}
    direction="row"
    justifyContent={justifyContent}>
    <Grid container item md={6} xs={6} justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item container alignItems="center" wrap="nowrap" style={{ maxWidth: '100%' }}>
        <OverflowTooltip>
          <Typography component="h3" variant="h6" color="primary" align="left" noWrap>
            {title}
          </Typography>
        </OverflowTooltip>
        {staleIndicator && (
          <Grid item sx={{ ml: 1 }}>
            {staleIndicator}
          </Grid>
        )}
        {updatePending && (
          <Grid item sx={{ mt: 1, ml: 1 }}>
            <Tooltip title={'Pending Update'}>
              <DownloadingRoundedIcon color="warning" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
    {children}
  </Grid>
);

export default WidgetHeader;
