import { query } from '../../api.gen';

export const GetDataSourceInstances = query('DataSourceInstances', q => [
  q.tenant(t => [
    t.__typename,
    t.id,
    t.dataSourceInstances(ds => [
      ds.__typename,
      ds.nodes(node => [
        node.__typename,
        node.id,
        node.name,
        node.key,
        node.dataSource(d => [
          d.$on('BundledDataSource', bds => [bds.__typename, bds.name]),
          d.$on('CustomDataSource', cds => [cds.__typename]),
          d.key
        ])
      ])
    ])
  ])
]);
